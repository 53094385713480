@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* Light theme colors (default) */
    --color-bg: #f5f5f5;
    --color-bg-variant: #e0e0e0;
    --color-primary: #2196f3;
    --color-primary-variant: rgba(33, 150, 243, 0.4);
    --color-white: #333;
    --color-light: rgba(51, 51, 51, 0.7);

    /* Container widths and transitions */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --transition: all 400ms ease;
}

/* Dark theme colors */
:root.-dark {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.5);
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

/*GENERAL STYLES*/

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 0rem;
    display: grid;
    place-items: center;
    align-content: center;
    min-height: 100vh;
}

section>h2,
section>h5 {
    text-align: center;
    color: var(--color-light)
}

section>h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.25rem 1.0rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1.5s;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0%);
}

/*MEDIA QUERIES*/

@media(prefers-reduced-motion) {
    .hidden {
        transition: none;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    .section {
        margin-top: 6rem;
    }
}

@media screen and (max-width:600px) {
    .container {
        width: var(--container-width-sm)
    }

    .section>h2 {
        margin-bottom: 2rem;
    }
}