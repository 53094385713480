/* Header Container */
.header {

    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

/* Header Content Layout */
.header__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem; /* Space between text and image */
}

/* Profile Image */
.header__image {
    flex-shrink: 0;
}

.header__profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid var(--color-primary);
    object-fit: cover;
}

/* CTA */
.cta {
    margin-top: 0.5rem;
    display: flex;
    gap: 1.2em;
    justify-content: center;
}

/* Socials */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    top: 1rem;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Scroll Down */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
}

@media screen and (max-width: 1024px) {
    header {
        height: 50vh;
    }
}

@media screen and (max-width: 600px) {
    header {
        padding-top: 7vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .header__content {
        flex-direction: column;
    }

    .header__image {
        margin-top: 1rem;
    }
}

/* For smaller screens with height less than 850px */
@media screen and (max-width: 600px) and (max-height: 800px) {
    header {
        height: 68vh;
    }
}

/* For screens with height greater than 850px */
@media screen and (max-width: 600px) and (min-height: 800px) {
    header {
        height: 50vh;
    }
}
